import * as React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import Timer from '@components/countdown'
import Pagination from '@components/pagination'
import ShowSpeaker from '@components/show-speaker'



const WebinarPagination = ({ data, pageContext }) => {
  return (
    <Layout pageTitle="Webinars">
      <Container>
        <div className="hero-banner large-hero light-hero">
          <StaticImage
            className="hero-image"
            src="../media/wessuc_default_hero.png"
            alt=""
            aspectRatio={4 / 1}
            layout="fullWidth" />
          <div className="hero-text">
            <h1>Webinars</h1>
             <Timer
              webDate={data.prismicWebinarAnnouncement?.data.date} 
              link={data.prismicWebinarAnnouncement?.data.register_link.text}/>
          </div>
        </div>
      </Container>
      <main>
      <Container fluid="xxl"><hr style={{color: '#b9b9b9'}}/></Container>
        {data.allPrismicWebinar.edges.map(({ node }) => (
        <div>
          <ShowSpeaker node={node}></ShowSpeaker>
          <hr style={{color: '#b9b9b9'}}></hr>
        </div>
        ))}
        <Container>
          <Row>
            <Col>
              <Pagination
                pageCount={pageContext.pageCount}
                currentPage={pageContext.currentPage}
                base="webinars"
              />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export const query = graphql`
query ($limit: Int!, $skip: Int!) {
  prismicWebinarAnnouncement {
    _previewable
    data {
      date
      register_link {
        text
      }
    }
  }
  allPrismicWebinar(
    skip: $skip
    limit: $limit
    sort: {fields: first_publication_date, order: DESC}
  ) {
    edges {
      node {
        _previewable
        id
        uid
        data {
          webinar_date(formatString: "MMM DD, YYYY")
          title {
            text
          }
          thumbnail {
            gatsbyImageData
          }
          embed_webinar {
            embed_url
          }
          description {
            richText
          }
          speakers_group {
            speaker {
              document {
                ... on PrismicSpeaker {
                  _previewable
                  id
                  data {
                    description {
                      richText
                      text
                    }
                    name {
                      text
                    }
                    speaker_img {
                      gatsbyImageData(height: 120)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } 
}`

export default WebinarPagination