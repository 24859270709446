import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import '../styles/countdown.scss'

function Timer({ webDate , link }) {

  const calculateTimeLeft = () => {
    const difference = Date.parse(webDate) - new Date().getTime()
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        DAYS: Math.floor(difference / (1000 * 60 * 60 * 24)),
        HRS: Math.floor((difference / (1000 * 60 * 60)) % 24),
        MINS: Math.floor((difference / 1000 / 60) % 60),
        SECS: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  };

  const timerNum = [];
  const timerStr = [];

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  Object.keys(timeLeft).forEach((interval) => {
    let intervalName = "";
    if (!timeLeft[interval]) {
      return;
    } else if (timeLeft[interval] == 1) {
    intervalName = interval.toString().slice(0,-1);
    } else {
    intervalName = interval.toString();
    }

    timerNum.push(
      <span>
        {timeLeft[interval]}
      </span>
    );
    timerStr.push(
      <span>
        {intervalName}
      </span>
    );
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div>
        {timerStr.length
        ?<Container>
          <div className="next-webinar">Next Webinar In:</div>
          <Row className="register-row" xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <Row className="timer">
                {timerNum.map(timerComponent => (
                <span className="timer-col">
                  {timerComponent}
                </span> 
                ))}
              </Row>
              <Row className="timeStr">
                {timerStr.map(timerComponent => (
                <span className="timeStr-col">
                  {timerComponent}
                </span> 
                ))}
              </Row>
            </Col>
            <Col className="button-col">
              <div className="center-button">
                <a href={link} target="_blank">
                  <Button variant='secondary' className='shadow'>
                    Register Here
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>

        :<div className="next-webinar">No Upcoming Webinars</div>
        }
    </div>
  )
}

export default Timer