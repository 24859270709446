import * as React from 'react'
import { useState, useRef } from 'react'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import AngleRight from '@assets/angle-right.svg'

import {
  postContainer,
  postImage,
  postFade,
  postText
} from '@styles/article-pagination.module.scss'

const ShowSpeaker = ({ node }) => {
  const speakerCount = node.data.speakers_group.length
  const cols = (speakerCount <= 2) ? 1 : 2

  const fade = useRef(0)
  const button = useRef(0)
  const [showSpeaker, setShowSpeaker] = useState(false)
  const [showInfo, setShowInfo] = useState(true)

  function swapContent() {
    fade.current.style.opacity = '0'
    button.current.style.opacity = '0'
    setTimeout(function() {toggleFade()}, 175)
  }

  function toggleFade() {
    fade.current.style.opacity = '1'
    button.current.style.opacity = '1'
    setShowSpeaker(!showSpeaker)
    setShowInfo(!showInfo)
  }

  return (
    <Container fluid="xxl">
      <Row xl={2} lg={2} md={2} sm={1} xs={1}>
        <Col>
          <Link
            to={`/webinars/${node.uid}`}
            className={postContainer}>
          {node.data && node.data.thumbnail
            ? <GatsbyImage className={postImage} image={node.data.thumbnail.gatsbyImageData} /> 
            : '' }
            <div className={postFade}>
              <div className={postText}>
                <div>{node.data.title.text}</div>
                <div>{node.data.webinar_date}</div>
              </div>
            </div>
          </Link>
        </Col>

        <Col ref={fade} className="content">
          {
          showInfo
          ?<div>
            <h1 className="title">{node.data.title?.text}</h1>
            <Row  className="ms-2 mt-2">
              <Col>
                <PrismicRichText field={node.data.description?.richText}/>
              </Col>
            </Row>
          </div>
          :null
          }
          
          {
          showSpeaker
          ?<div>
              <h1 className="title">Speakers</h1>
            <Row xl={cols} lg={cols} md={cols} sm={cols} xs={cols}  className="ms-2 mt-2">
              {node.data.speakers_group?.map(({ speaker }) => (
                <Col>
                {speakerCount <= 2
                  ?<Row>
                    <Col className='pe-0' xl={3} lg={3} md={3} sm={3} xs={3}>   
                      <div className="speaker-icons">
                        <GatsbyImage image={speaker.document.data.speaker_img?.gatsbyImageData}/>
                      </div>
                    </Col>
                    <Col>
                      <h2>{speaker.document.data.name?.text}</h2>
                      <PrismicRichText field={speaker.document.data.description?.richText}/>
                    </Col>
                  </Row>
                  :<Row >
                    <Col>
                      <h2>{speaker.document.data.name?.text}</h2>
                      <PrismicRichText field={speaker.document.data.description?.richText}/>
                    </Col>
                  </Row>
                  }
                </Col>
              ))}
            </Row>
          </div>
          :null
          }

        </Col>
      </Row>
      {node.data.speakers_group[0]?.speaker?.document
        ?<Row className="text-center ms-5 ps-5">
          <Col ref={button} className='content'>
            {showSpeaker
            ?<div>
              <span className="display-speaker" onClick={swapContent}>Webinar Info &nbsp;
                <AngleRight className="angle-down arrow"/>
              </span>
            </div>
            :null}
            {showInfo
            ?<div>
              <span className="display-speaker" onClick={swapContent}>Speakers &nbsp;
                <AngleRight className="angle-down arrow"/>
              </span>
            </div>
            : null}
          </Col>
        </Row>
        :null}
    </Container>
  )
}

export default ShowSpeaker